<div [ngClass]="_containerSelectClass">
    <ng-select
        [ngModel]="value"
        (ngModelChange)="onChange($event)"
        [addTag]="allowAdd"
        [addTagText]="addText"
        bindLabel="label"
        bindValue="value"
        [clearable]="clearable"
        [clearAllText]="clearAllText"
        [clearOnBackspace]="clearOnBackspace"
        [clearSearchOnAdd]="clearSearchOnAdd"
        [closeOnSelect]="closeOnSelect"
        [disabled]="disabled || readonly || loading"
        [dropdownPosition]="dropdownPosition"
        [groupBy]="groupBy ? 'group' : undefined"
        [hideSelected]="hideSelected"
        [isOpen]="isOpen"
        [items]="innerItems"
        [labelForId]="labelForId ? 'id' : undefined"
        [loading]="loading"
        [loadingText]="loadingText"
        [multiple]="multiple"
        [ngClass]="_selectClass"
        [notFoundText]="notFoundText"
        [placeholder]="placeholder"
        [searchable]="searchable"
        [selectableGroup]="selectableGroup"
        [selectableGroupAsModel]="selectableGroupAsModel"
        [typeToSearchText]="typeToSearchText"
        [virtualScroll]="virtualScroll"
        [tabindex]="tabindex"
        [maxSelectedItems]="maxSelectedItems"
        (add)="onOptionAdd($event)"
        (blur)="onBlur($event)"
        (change)="onOptionChange($event)"
        (clear)="onClear($event)"
        (close)="onClose($event)"
        (focus)="onFocus($event)"
        (open)="onOpen($event)"
        (remove)="onOptionRemove($event)"
        (scroll)="onScroll($event)"
        (scrollToEnd)="onScrollToEnd($event)"
        (search)="onSearch($event)"
    >
        <!--
        Multiple without chips
      -->
        <ng-template ng-multi-label-tmp *ngIf="multiple && !showChips">
            <div class="value-select">{{ multiLabelCache }}</div>
        </ng-template>

        <!--
      Label
    -->
        <ng-template ng-label-tmp let-item="item">
            <div [class]="item.className">
                <span class="content-icon">
                    <ng-container *ngIf="item.icons?.length">
                        <i *ngFor="let icon of item.icons" class="la {{ icon }}" aria-hidden="true"></i>
                    </ng-container>
                    <ng-container *ngIf="showChips && canRemoveChips && multiple">
                        <button class="ng-value-chip-close" (mousedown)="onChipRemove($event, item)">
                            &#215;
                        </button>
                    </ng-container>
                    <span>
                        <span *ngIf="preLabel">{{ preLabel }}</span>
                        {{ item.label }}
                    </span>
                    <ng-container *ngIf="descriptionKey || descriptionCallback">
                        <span class="ng-value-description">
                            {{ item.description }}
                        </span>
                    </ng-container>
                </span>
            </div>
        </ng-template>

        <!--
        Option
      -->
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <span title="{{ item.label }}" class="content-icon">
                <ng-container *ngIf="item.icons?.length">
                    <i *ngFor="let icon of item.icons" class="la {{ icon }}" aria-hidden="true"></i>
                </ng-container>
                <span>{{ item.label }}</span>
                <ng-container *ngIf="descriptionKey || descriptionCallback">
                    <span class="ng-value-description">
                        {{ item.description }}
                    </span>
                </ng-container>
            </span>
            <button
                *ngIf="item.isRemovable"
                class="ng-option-removeButton"
                emmaButton
                [hasIcon]="true"
                [onlyIcon]="true"
                [noBg]="true"
                [isCircle]="true"
                color="danger"
                (click)="onRemoveItem($event, item.value)"
            >
                <i class="la la-trash m--font-danger" aria-hidden="true"></i>
            </button>
        </ng-template>

        <!--
        Footer
      -->
        <ng-template ng-footer-tmp *ngIf="(multiple && allowSelectAll) || allowLoadMore">
            <div>
                <ng-container *ngIf="allowLoadMore">
                    <button
                        emmaButton
                        color="metal"
                        [isBlock]="true"
                        sizeButton="sm"
                        [hasIcon]="true"
                        (click)="onLoadMore()"
                    >
                        <span>
                            <i
                                [ngClass]="{ 'la la-redo-alt': true, 'la-spin': loading }"
                                aria-hidden="true"
                            ></i>
                            <span i18n>Cargar más</span>
                        </span>
                    </button>
                </ng-container>
                <ng-container *ngIf="multiple && allowSelectAll">
                    <button
                        emmaButton
                        [disabled]="value.length === innerItems.length"
                        color="metal"
                        [isBlock]="true"
                        sizeButton="sm"
                        [hasIcon]="true"
                        (click)="onSelectAll()"
                    >
                        <span>
                            <i class="la la-list-alt" aria-hidden="true"></i>
                            <span>{{ selectAllButtonLabel }}</span>
                        </span>
                    </button>
                    <button
                        emmaButton
                        [disabled]="value.length <= defaultFirstValues || value.length <= minlength"
                        color="metal"
                        [isBlock]="true"
                        sizeButton="sm"
                        [hasIcon]="true"
                        (click)="onClearAll()"
                    >
                        <span>
                            <i class="la la-list-ul" aria-hidden="true"></i>
                            <span>{{ deselectAllButtonLabel }}</span>
                        </span>
                    </button>
                </ng-container>
            </div>
        </ng-template>
    </ng-select>
    <div
        class="helpMessageAtError"
        #popover="ngbPopover"
        *ngIf="showHelpMessageAtError"
        [ngbPopover]="helpMessageAtError"
        placement="top"
        triggers="manual"
        container="body"
    ></div>
</div>
