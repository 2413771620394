import countriesJson from '../json/countries.service.json';

export const DEFAULT_CURRENCY = 'EUR';

const currencySymbols: { [key: string]: string } = {};
countriesJson.forEach((country) => {
  if (country.currencies) {
    country.currencies.forEach((currency) => {
      if (!currencySymbols[currency.code]) {
        currencySymbols[currency.code] = currency.symbol;
      }
    });
  }
});

export const isCurrencyCode = (value: string): boolean => {
  return Boolean(currencySymbols[value]);
};

export const getCurrencySymbol = (currencyCode: string) => {
  return currencySymbols[currencyCode] || currencySymbols[DEFAULT_CURRENCY];
};
