// tslint:disable: unified-signatures
import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Timezone, timezones } from '../helpers/timezones.helper';

import countriesJson from '../json/countries.service.json';
import { LocaleService } from './locale.service';
// Soporte para la traducción de los nombres de los idiomas
import languages from '@cospired/i18n-iso-languages';
import * as langEn from '@cospired/i18n-iso-languages/langs/en.json';
import * as langEs from '@cospired/i18n-iso-languages/langs/es.json';
languages.registerLocale(langEn);
languages.registerLocale(langEs);

export interface Country {
  name: string;
  topLevelDomain: string[];
  alpha2Code: string;
  alpha3Code: string;
  nativeName: string;
  translations: { [k: string]: string | undefined };
  cioc?: string;
  timezones: string[];
  currencies?: Array<{ code: string | null; name: string | null; symbol: string | null }>;
  [k: string]: unknown;
}

export const countriesToOptions = (
  lang: string,
  countries: Country[],
  valueName = false
): { value: string; label: string }[] => {
  const result: { value: string; label: string }[] = [];
  for (const country of countries) {
    let label: string;
    if (!lang) {
      label = country.nativeName;
    } else if (lang === 'en') {
      label = country.name;
    } else {
      label = country.translations[lang] || country.nativeName;
    }
    result.push({
      value: valueName ? country.name : country.alpha2Code,
      label,
    });
  }
  return result;
};

export const currenciesToOptions = (countries: Country[]): string[] => {
  const currencies = new Set<string>();
  for (const country of countries) {
    if (country.currencies) {
      for (const currency of country.currencies) {
        if (currency.code) {
          currencies.add(currency.code);
        }
      }
    }
  }
  const currenciesArray = Array.from(currencies)
    .filter(Boolean)
    .filter((c) => c !== '(none)');
  currenciesArray.sort();
  return currenciesArray;
};

export const localizeCountryByEnglishName = (countries: Country[], name: string, lang?: string): string => {
  let countryName = name;
  const country = countries.find((country) => country.name === name);
  if (country) {
    if (!lang) {
      countryName = country.nativeName;
    } else if (lang === 'en') {
      countryName = country.name;
    } else {
      countryName = country.translations[lang] || country.nativeName;
    }
  }
  return countryName;
};
export const localizeCountryByAlpha2Code = (countries: Country[], code: string, lang?: string): string => {
  let countryName = code;
  const country = countries.find(
    (country) => code && country.alpha2Code.toLowerCase() === code.toLowerCase()
  );
  if (country) {
    if (!lang) {
      countryName = country.nativeName;
    } else if (lang === 'en') {
      countryName = country.name;
    } else {
      countryName = country.translations[lang] || country.nativeName;
    }
  }
  return countryName;
};
/**
 * Traducir la parte correspondiente al nombre del idioma en los códigos iso tipo es_AR, en_US
 * 2 primeras letras son el código del idioma (es, en, fr ...)
 * 2 últimas letras son el código del país (es, ar, us, en ...)
 */
export const localizeLanguageByIsoCode = (countries: Country[], isoCode: string, lang?: string): string => {
  let languageName = '';
  const languageParts = isoCode.replaceAll('-', '_').split('_');
  const languageCode = languageParts.length > 0 ? languageParts[0] : undefined;
  if (languageCode) {
    if (lang === 'es') {
      languageName = languages.getName(languageCode, lang) ?? '';
    } else {
      languageName = languages.getName(languageCode, 'en') ?? '';
    }
  }

  const languageCountryCode = languageParts.length > 1 ? languageParts[1] : undefined;
  const languageCountryCodeName = languageCountryCode
    ? localizeCountryByAlpha2Code(countries, languageCountryCode, lang)
    : '';

  let finalLanguageName = '';
  if (languageName.length > 0 && languageCountryCodeName.length > 0) {
    finalLanguageName = `${languageName}, ${languageCountryCodeName}`;
  } else if (languageName.length > 0) {
    finalLanguageName = languageName;
  }
  return finalLanguageName;
};

@Injectable({ providedIn: 'root' })
export class CountriesService {
  constructor(private localeService: LocaleService) {}

  public getCountries(): Observable<Country[]>;
  /** @deprecated */
  public getCountries(fields: Array<string>): Observable<Country[]>;
  // eslint-disable-next-line class-methods-use-this
  public getCountries(): Observable<Country[]> {
    return of(countriesJson);
  }
  // eslint-disable-next-line class-methods-use-this
  public getTimezones(): Timezone[] {
    return timezones;
  }
  public getCountriesOptions(): Observable<{ value: string; label: string }[]> {
    return this.getCountries().pipe(
      map((countries) => sortBy(countriesToOptions(this.localeService.getLanguage(), countries), 'label'))
    );
  }
  public getTimezoneOptions(): { value: string; label: string }[] {
    return this.getTimezones().map((tz) => ({
      value: tz.identifier,
      label: `${tz.names[this.localeService.getLanguage()] || tz.identifier} (${tz.offset})`,
    }));
  }
}
